import React from "react";
import "./Partners.scss";
import Footer from "../../components/Footer/Footer";
import wim from "../../assets/partners/0.png";
import kow1 from "../../assets/partners/1.jpg";
import kow3 from "../../assets/partners/3.jpg";

const Partners = () => {
  return (
    <main className="Partners">
      <div className="grid">
        <a href="https://wojski.com.pl" target="_blank" rel="noreferrer">
          <img src={wim} alt="" />
        </a>
        <a href="http://www.jrs-kowalewko.pl" target="_blank" rel="noreferrer">
          <img src={kow3} alt="" />
        </a>
        <a href="http://www.jrs-kowalewko.pl" target="_blank" rel="noreferrer">
          <img src={kow1} alt="" />
        </a>
      </div>
      <Footer />
    </main>
  );
};

export default Partners;

// wojski.com.pl
