import React from "react"
import "./Event.scss"

const Event = ({ name, termin, start, place }) => {
  return (
    <div className="Event">
      <div className="name">{name}</div>
      <div className="prop">TERMIN: {termin}</div>
      <div className="prop">START: {start}</div>
      <div className="prop">MIEJSCE: {place}</div>
    </div>
  )
}

export default Event
