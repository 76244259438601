import React from "react"
import { useState } from "react"
import Logo1 from "../../assets/logo/LOGO-01.png"
import Logo2 from "../../assets/logo/LOGO-02.png"
import Logo3 from "../../assets/logo/LOGO-03.png"
import Logo4 from "../../assets/logo/LOGO-04.png"
import Logo5 from "../../assets/logo/LOGO-05.png"
import "./Logo.scss"

const Logo = ({ className }) => {
  const logos = [Logo1, Logo2, Logo3, Logo4, Logo5]
  const [logo, setLogo] = useState(Logo1)
  const [num, setNum] = useState(0)

  const chandleChangeLogo = () => {
    let i = num + 1
    if (i > 4) i = 0
    setNum(i)
    setLogo(logos[i])
  }

  return <img className={`Logo ${className}`} src={logo} alt="logo" onClick={chandleChangeLogo} />
}

export default Logo
