import React from "react"
import { TfiTarget } from "react-icons/tfi"
import { GiPistolGun, GiPoliceTarget, GiPoliceOfficerHead } from "react-icons/gi"
import "./Benefits.scss"

const Benefits = () => {
  return (
    <section className="Benefits">
      <div className="card card-light">
        <div className="description">
          Chcemy zaprosić Państwa do zapoznania się z ofertą firmy Strzelnica Bydgoszcz, która organizuje szkolenia strzeleckie. Od blisko 20 lat zajmujemy się
          strzelaniem, od 12 szkolimy w tym zakresie w jednym z resortów, teraz nadszedł czas aby podzielić się wiedzą i doświadczeniem z Wami. Pracując na
          strzelnicy zauważyliśmy wzrost liczby osób zaczynających przygodę ze sportem strzeleckim, niestety często osoby te próbują we własnym zakresie
          podnosić swoje kwalifikacje. Strzelnica Bydgoszcz to firma jakiej brakowało w Naszym regionie.
        </div>
      </div>
      <div className="card card-dark">
        <div className="description">
          Jako firma Strzelnica Bydgoszcz organizujemy spotkania integracyjne w ramach których oprócz świetnej zabawy uczymy bezpieczeństwa w posługiwaniu się
          bronią. Na stale współpracujemy ze sklepami handlującymi bronią i amunicją co pozwala na uzyskanie atrakcyjnych rabatów na zakupy. Naszymi klientami
          docelowo są osoby posiadające już swoją broń ale dla osób przygotowujących się do egzaminu zapewniamy broń. Dla Was organizujemy indywidualne
          szkolenia strzeleckie.
        </div>
      </div>
      <div className="card card-light">
        <div className="description">
          Strzelnica Bydgoszcz współpracuje z wieloma strzelnicami w regionie. Na wszystkich naszych obiektach dajemy Ci możliwość przećwiczenia strzelania na
          żywo w strzelaniu zaawansowanym. Na Naszych szkoleniach strzeleckich dostępny jest pełen zestaw broni, narzędzi i akcesoriów. Instruktorzy są
          doświadczeni i przeszkoleni, dzięki czemu szkolenie strzeleckie jest bezpieczne i efektywne. Współpracujemy ze sprawdzonymi strzelnicami i
          gwarantujemy wyjątkowe warunki.
        </div>
      </div>
      <div className="card card-dark">
        <div className="description">
          Strzelanie to bardzo trudna i wymagająca dyscyplina sportowa. Wymaga od strzelca gruntownego przygotowania teoretycznego a następnie wielu godzin
          treningu. Nie bez znaczenia pozostaje wzrost kosztów amunicji, wystrzelanie 200/300 sztuk amunicji często okazuje się świetną zabawą i tylko zabawą.
          Nasze szkolenia strzeleckie cechuje zasada minimum amunicji maksimum umiejętności. Poziom szkolenia strzeleckiego zawsze dopasowujemy do umiejętności
          uczestników. W ofercie firmy Strzelnica Bydgoszcz znajdziecie następujące szkolenia strzeleckie: szkolenia do egzaminu, szkolenia z zakresu strzelania
          sportowego, szkolenia z zakresu strzelania dynamicznego i bojowego.
        </div>
      </div>
    </section>
  )
}

export default Benefits
