import React from "react";
import { GiHeavyBullets } from "react-icons/gi";
import ola from "../../assets/images/ola.jpg";
import tomek from "../../assets/images/tomek.jpg";
import "./Staff.scss";

const Staff = () => {
  return (
    <section className="Staff">
      <GiHeavyBullets className="icon" />
      <p className="title">NASZ ZESPÓŁ</p>
      <p className="description">
        Poznaj osoby, które są zaangażowane w to, aby szkolenie strzeleckie było bezpiecznie, efektywnie i przyjemne. Jesteśmy
        doświadczonymi strzelcami z certyfikatami i gwarantujemy komfortowe warunki do ćwiczeń.
      </p>
      <div className="instructor">
        <div className="card">
          <img className="img" src={tomek} alt="tomek" />
          <p className="name">Tomasz „JARO” Jaromin</p>
          {/* <p className="role">CEO Założyciel</p> */}
          <p className="about">
            Od ponad 20 lat związany ze strzelaniem. Od 12 lat zawodowo zajmuje się szkoleniem strzeleckim, instruktor strzelania
            sportowego, dynamicznego, bojowego. W strzelaniu kieruje się zasadą bezpieczeństwo ponad wszystko. Nie posiada monopolu na
            wiedzę, chętnie uczy się nowych sposobów na celne strzelanie, otwarty na nowe umiejętności i tego samego uczy uczestników swoich
            szkoleń.
          </p>
        </div>
        <div className="card">
          <img className="img" src={ola} alt="tomek" />
          <p className="name">Aleksandra „OLKA” Jaromin</p>
          {/* <p className="role">Instruktor</p> */}
          <p className="about">
            Od kilku lat pracuje jako instruktor strzelania i z powodzeniem potwierdza regułę, że kobiety strzelają lepiej. Finalistka i
            triumfatorka wielu zawodów rangi krajowej. Siła spokoju sprawia, że statyka to jej żywioł. Potrafi każdego nauczyć celnie
            strzelać, równie celnie i sprawnie interpretuje wyniki na tarczy aby wskazać obszary do pracy. Zwolenniczka treningu bez
            strzałowego. Wiecznie uśmiechnięta optymistka. Posiadaczka jedynego w kraju a może na świecie różowego Glocka Punisher KITTY.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Staff;
