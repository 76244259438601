import React from "react"
import "./Contact.scss"
import { GiSilverBullet } from "react-icons/gi"
import { SlPhone } from "react-icons/sl"
import { TfiEmail } from "react-icons/tfi"
import { BsCardText } from "react-icons/bs"

const Contact = () => {
  return (
    <section className="Contact">
      <div className="intro">
        <GiSilverBullet className="icon" />
        <div className="title">CZEKAMY NA CIEBIE</div>
        <div className="company">SZKOLENIA STRZELECKIE TOMASZ JAROMIN</div>
        <div className="description">Działamy na strzelnicach w Bydgoszczy i okolicach.</div>
      </div>
      <div className="card">
        <div className="info">
          <SlPhone className="img" />
          <div className="data">
            <p className="label">TELEFON</p>
            <p>605 437 446</p>
          </div>
        </div>
        <div className="info">
          <BsCardText className="img" />
          <div className="data">
            <p className="label">REGON</p>
            <p>093132781</p>
          </div>
        </div>
        <div className="info">
          <BsCardText className="img" />
          <div className="data">
            <p className="label">NIP</p>
            <p>9670429483</p>
          </div>
        </div>
        <div className="info">
          <TfiEmail className="img" />
          <div className="data">
            <p className="label">EMAIL</p>
            <p>info@strzelnicabydgoszcz.pl</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
