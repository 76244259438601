import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import MobileNavbar from "./MobileNavbar/MobileNavbar"
import { GrMenu } from "react-icons/gr"
import Logo from "../Logo/Logo"
import "./Navbar.scss"

const Navbar = () => {
  const { pathname } = useLocation()

  const [navOpen, setNavOpen] = useState(false)

  const scroll = (component) => {
    const delay = pathname === "/" ? 0 : 100
    setTimeout(() => {
      const section = document.querySelector(`.${component}`)
      section.scrollIntoView({ behavior: "smooth", block: "start" })
    }, delay)
  }

  return (
    <nav className="Navbar">
      {navOpen && <MobileNavbar setNavOpen={setNavOpen} />}
      <Logo />
      <GrMenu
        className="menu-icon"
        onClick={() => {
          setNavOpen(true)
        }}
      />
      <div className="links">
        <Link to="/" className="link-red" onClick={() => scroll("Home")}>
          STRONA GŁÓWNA
        </Link>
        <Link to="/" onClick={() => scroll("Staff")}>
          O NAS
        </Link>
        <Link to="/" onClick={() => scroll("Offers")}>
          PAKIETY
        </Link>
        <Link to="/" onClick={() => scroll("Gifts")}>
          KUP PREZENT
        </Link>
        <Link to="/" onClick={() => scroll("Events")}>
          TERMINARZ
        </Link>
        <Link to="/" onClick={() => scroll("Contact")}>
          KONTAKT
        </Link>
        <Link to="/partnerzy">PARTNERZY</Link>
      </div>
    </nav>
  )
}

export default Navbar
