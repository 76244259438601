import React, { useState, useEffect } from "react"
import "./Events.scss"
import Event from "./Event/Event"
import { RiCalendarEventFill } from "react-icons/ri"

const Events = () => {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchEvents = async () => {
    try {
      const res = await fetch("db.php")
      const data = await res.json()
      setEvents(data)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchEvents()
  }, [])

  return (
    <section className="Events">
      <RiCalendarEventFill className="icon" />
      <div className="title">Nadchodzące szkolenia</div>
      <div className="description">
        W tym sezonie czeka nas jeszcze więcej ciekawych spotkań! Dołącz do nas na specjalnych szkoleniach, podczas
        których spotkasz profesjonalnych strzelców, nauczysz się nowych rzeczy i przećwiczysz swoje umiejętności!
      </div>
      <div className="multi">
        {loading ? <p className="message">Wczytywanie...</p> : null}
        {!loading && events.length > 0
          ? events.map((event) => (
              <Event
                key={event.id}
                name={event.nazwa}
                termin={event.termin}
                start={event.start}
                place={event.miejsce}
              />
            ))
          : null}
        {!loading && events.length === 0 ? <p className="message">Brak nadchodzacych szkoleń</p> : null}
      </div>
    </section>
  )
}

export default Events
