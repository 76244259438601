import React from "react"
import { FaInstagram, FaFacebookF } from "react-icons/fa"
import { GiBullets } from "react-icons/gi"
import "./Offers.scss"
import Card from "./Card/Card"
// img
import img1 from "../../assets/images/soldier1.jpg"
import img2 from "../../assets/images/soldier2.jpg"
import img3 from "../../assets/images/soldier3.jpg"
import img4 from "../../assets/images/soldier4.jpg"
import img5 from "../../assets/images/soldier5.jpg"
import img6 from "../../assets/images/soldier6.jpg"
import img7 from "../../assets/images/soldier7.jpg"
//

const Offers = () => {
  return (
    <section className="Offers">
      <GiBullets className="img" />
      <div className="offer-title">Dostępne pakiety</div>
      <div className="title">
        Jesteś zdecydowany lub nie wiesz co wybrać zadzwoń <span style={{ whiteSpace: "nowrap" }}>605 437 446.</span> Pomożemy wybrać
        odpowiedni pakiet dla Ciebie.
      </div>
      <div className="cards">
        <Card
          img={img3}
          title="Pakiet dla dzieci i młodzieży 200zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Sig Sauer 1911 kal 22 Lr (10 strzałów)",
            "Karabinek WINCHESTER kal 22 Lr (10 strzałów)",
            "Karabinek Rossi kal 22 Lr	(10 strzałów)",
            "Strzelba PUMP- ACTION Shotgun kal 22Lr (10 strzałów)",
            "Łącznie 40 strzałów",
          ]}
          footer="W cenie szkolenie z zasad bezpieczeństwa, opłata za strzelnicę, tarcze strzeleckie, ochronniki słuchu i wzroku oraz opieka instruktora"
        />
        <Card
          img={img4}
          title="Pakiet 200zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Sig Sauer 1911 kal 22 Lr (10 strzałów)",
            "Karabinek Rossi kal 22 Lr	(10 strzałów)",
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Łącznie 40 strzałów",
          ]}
          footer="W cenie szkolenie z zasad bezpieczeństwa, opłata za strzelnicę, tarcze strzeleckie, ochronniki słuchu i wzroku oraz opieka instruktora"
        />
        <Card
          img={img1}
          title="Pakiet 300zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Rewolwer ASTRA kal  9 mm (6 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Karabinek AR 15 Taurus T4 kal 223 REM (5 strzałów)",
            "Karabinek AK 47 SADU mini Dragunov (5 strzałów)",
            "Strzelba PUMP- ACTION Shotgun kal 17/70 (5 strzałów)",
            "Łącznie 41 strzałów",
          ]}
          footer="W cenie szkolenie z zasad bezpieczeństwa, opłata za strzelnicę, tarcze strzeleckie, ochronniki słuchu i wzroku oraz opieka instruktora"
        />
        <Card
          img={img6}
          title="Pakiet 400zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Rewolwer ASTRA kal  9 mm (6 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Karabinek AR 15 Taurus T4 kal 223 REM (10 strzałów)",
            "Karabinek AK 47 SADU mini Dragunov (10 strzałów)",
            "Pistolet maszynowy PPS kal 7,62 (5 strzałów)",
            "Strzelba MOSSBERG 930 półautomat Shotgun (6 strzałów)",
            "Łącznie 57 strzałów",
          ]}
          footer="W cenie szkolenie z zasad bezpieczeństwa, opłata za strzelnicę, tarcze strzeleckie, ochronniki słuchu i wzroku oraz opieka instruktora"
        />
        <Card
          img={img7}
          title="Pakiet MEGA 600zł"
          dataTitle="Pakiet zawiera strzelanie z broni:"
          data={[
            "Pistolet Glock kal 9 mm (10 strzałów)",
            "Rewolwer ASTRA kal  9 mm (6 strzałów)",
            "Karabinek Scorpion kal 9 mm (10 strzałów)",
            "Karabinek AR 15 Taurus T4 kal 223 REM (10 strzałów)",
            "Karabinek AK 47 SADU mini Dragunov (10 strzałów)",
            "Pistolet maszynowy PPS kal 7,62 (10 strzałów)",
            "Strzelba PUMP- ACTION Shotgun kal 17/70 (6 strzałów)",
            "Strzelba MOSSBERG 930 półautomat Shotgun (10 strzałów)",
            "Łącznie 72 strzały",
          ]}
          footer="W cenie szkolenie z zasad bezpieczeństwa, opłata za strzelnicę, tarcze strzeleckie, ochronniki słuchu i wzroku oraz opieka instruktora"
        />
        <Card
          img={img5}
          title="Szkolenia"
          dataTitle="Oferujemy również szkolenia:"
          data={["Indywidualne", "Grupowe", "Dla szkół mundurowych i nie tylko"]}
          footer="Stawki ustalamy indywidualnie w zależności od zakresu szkolenia"
        />
        <Card
          img={img2}
          title="Imprezy okolicznościowe"
          dataTitle="Organizujemy imprezy okolicznościowe:"
          data={["Kawalerskie, Panieńskie, Urodzinowe,", "Integracyjne, Firmowe,", "Spotkanie klasowe,", "Świąteczne i inne"]}
          footer="Oferowany zakres usługi uzależniony jest od wymagań i oczekiwań zamawiającego. Na podstawie posiadanego budżetu planujemy indywidulanie przebieg imprezy. Zazwyczaj koszt imprezy zaczyna się od 250 zł od osoby, w ramach ceny oferujemy strzelanie dla uczestników, ognisko lub grill, kiełbasa, kawa, herbata."
        />
      </div>
      <div className="title">
        Zachęcamy Cię do śledzenia naszych działań oraz pozostawania na bieżąco poprzez nasze profile na Instagramie i Facebooku.
      </div>
      <div className="social-links">
        <a className="frame" href="https://www.instagram.com/strzelnica_bydgoszcz/" target="_blank" rel="noreferrer">
          <FaInstagram className="social-link" />
        </a>
        <a className="frame" href="https://www.facebook.com/profile.php?id=100091530323055" target="_blank" rel="noreferrer">
          <FaFacebookF className="social-link" />
        </a>
      </div>
    </section>
  )
}

export default Offers
