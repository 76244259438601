import React from "react"
import styled from "styled-components"

import CardBack from "./CardBack"
import CardFront from "./CardFront"

const Card = ({ img, title, dataTitle, data, footer }) => {
  return (
    <Box>
      <div className="inner">
        <CardFront title={title} img={img} />
        <CardBack dataTitle={dataTitle} data={data} footer={footer} />
      </div>
    </Box>
  )
}

export default Card

const Box = styled.div`
  width: 400px;
  height: 300px;
  cursor: pointer;

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  &:hover .inner {
    transform: rotateY(180deg);
  }
`
