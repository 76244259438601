import React from "react"
import { FaInstagram, FaTiktok, FaFacebookF } from "react-icons/fa"
import "./Footer.scss"

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="social-links">
        <a className="frame" href="https://www.instagram.com/strzelnica_bydgoszcz/" target="_blank">
          <FaInstagram className="social-link" />
        </a>
        <a className="frame" href="https://www.facebook.com/profile.php?id=100091530323055" target="_blank">
          <FaFacebookF className="social-link" />
        </a>
      </div>
      <div className="name">© 2023 strzelnicabydgoszcz.pl</div>
      <div className="contact">
        <div className="phone">Telefon: 605 437 446</div>
        <div className="email">Email: info@strzelnicabydgoszcz.pl</div>
      </div>
      <div className="freepick">
        Część użytych na stronie obrazów jest autorstwa
        <a href="https://pl.freepik.com"> Freepik</a>
      </div>
    </footer>
  )
}

export default Footer
